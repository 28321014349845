<template>
  <v-container class="main">
    <div class="top-text">
      <h1>גבריאל <v-icon color="error">mdi-heart</v-icon> הדר</h1>
      <h2>אישור הגעה</h2>
    </div>

    <form>
      <v-row align="center" justify="center">
        <v-col sm="6" md="2">
          <v-text-field
            dir="auto"
            label="שם משפחה"
            v-model="surname"
          ></v-text-field>
        </v-col>
        <v-col sm="6" md="2">
          <v-text-field
            dir="auto"
            label="שם פרטי"
            v-model="name"
          ></v-text-field>
        </v-col>
      </v-row>
      <v-row align="center" justify="center">
        <v-col cols="6" md="2">
          <v-text-field
            dir="auto"
            type="number"
            label="מספר נייד"
            v-model="cellphone"
          ></v-text-field>
        </v-col>
      </v-row>

      <v-row align="center" justify="center" class="counter-wrap">
        <div class="counter">
          <v-btn
            @click="guestCount--"
            :disabled="guestCount > 1 ? false : true"
            outlined
            fab
            small
            color="indigo"
          >
            <v-icon>mdi-minus</v-icon>
          </v-btn>

          <v-text-field
            filled
            class="ma-1 guest-input"
            min="0"
            v-model="guestCount"
            type="number"
          ></v-text-field>
          <v-btn @click="guestCount++" small outlined fab color="indigo">
            <v-icon>mdi-plus</v-icon>
          </v-btn>
        </div>
        <h3 class="coming-text">?כמה אתם</h3>
      </v-row>

      <v-row align="center" justify="center" class="arriving-wrap mb-13">
        <v-col cols="12" v-if="name == '' || surname == '' || cellphone > 1">
          <p class="text-center">
            נא למלא את הפרטים לפני אישור <v-icon>mdi-alert</v-icon>
          </p></v-col
        >

        <v-btn
          color="error"
          class="mr-5"
          :disabled="name == '' || surname == '' || cellphone == null"
          @click="(coming = false), submit()"
        >
          <div class="text">לא מגיעים</div>
          <v-icon>mdi-close</v-icon>
        </v-btn>

        <v-btn
          color="success"
          :disabled="name == '' || surname == '' || cellphone == null"
          @click="(coming = true), submit()"
        >
          <div class="text">מגיעים</div>
          <v-icon>mdi-check</v-icon>
        </v-btn>
      </v-row>
    </form>
    <!-- {{ details }} -->
  </v-container>
</template>

<script>
export default {
  name: "Main",

  data: () => ({
    guestCount: 1,
    coming: false,
    name: "",
    surname: "",
    cellphone: null,
  }),
  methods: {
    submit: function () {
      console.log("submit");
      console.log(this.details);

      this.axios
        .post("/guests", this.details)
        .then((response) => {
          // Handle success.
          console.log(response);
        })
        .catch((error) => {
          // Handle error.
          console.log("An error occurred:", error.response);
        });
    },
  },
  computed: {
    // a computed getter
    details: function () {
      // `this` points to the vm instance
      return {
        Name: this.name,
        Surname: this.surname,
        Cell: parseInt(this.cellphone),
        Guests: this.guestCount,
        Coming: this.coming,
      };
    },
  },
};
</script>
<style lang="scss">
.top-text {
  text-align: center;
}
.v-label {
  left: unset !important;
  right: 0 !important;
}
.text {
  margin-right: 7px;
}
.main {
  padding: 30px;
}
.counter {
  input {
    text-align: center;
  }
  display: flex;
  align-items: center;
  justify-content: space-around;
  width: 150px;
}
.coming-text {
  margin-left: 40px;
  font-size: 25px;
}
</style>
